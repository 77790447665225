<template>
<div class="pa-10">

    <teams />
</div>
</template>
<script>
import teams from '@/components/teams.vue'
export default {
    components:{
        teams
    },
    data(){
        return{ 
        }
    }
}
</script>
<style>
</style>