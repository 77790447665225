<!-- 
System: Whistle It
Developer: Jazib Nasir
Date: 17-06-2021 
Organization: Programmer's Force
Purpose: Component for displaying Teams data
-->

<template>
  <div>
    <v-sheet
      color="white"
      :height="$vuetify.breakpoint.xs ? '130' : '83'"
      width="100%"
      :class="$vuetify.breakpoint.xs ? 'd-flex flex-column' : 'd-flex '"
      class="px-8 py-8"
    >
      <!---------------------------------------------Search Textfield------------------------------------------------>
      <v-text-field
        class="mt-n3"
        v-model="search"
        style="max-width: 200px"
        prepend-inner-icon="mdi-magnify"
        label="Search"
        placeholder="Search Team"
        single-line
        outlined
        dense
        autofocus
        color="admin-primary"
        hide-details
      >
      </v-text-field>
      <v-spacer></v-spacer>
      <v-select
        class="mt-n3"
        style="max-width: 200px"
        v-model="select"
        :items="companiesList"
        label="Select Company"
        dense
        solo
        outlined
      >
      </v-select>
    </v-sheet>

    <v-skeleton-loader
      v-if="firstLoad"
      :loading="loading"
      type="table-thead,table-tbody"
    ></v-skeleton-loader>
    <!--------------------------------------Data Table to display Teams Data ----------------------------->
    <v-data-table
      :headers="headers"
      :items="teams"
      v-show="!firstLoad"
      class="shadow-class tableRowBg"
      hide-default-footer
      :search="search"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      :item-class="rowClass"
      :loading="loading"
    >
      <!-------------------------------------------Dialogue Box for Edit------------------------------------------->
      <template v-slot:top>
        <v-dialog v-model="dialog" persistent max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
              <v-spacer></v-spacer>
              <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <!-----------------------------------------------Name Textfield------------------------------------------->
                    <v-text-field
                      v-model="editedItem.name"
                      label="Team name"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-----------------------------------------------------Buttons------------------------------------------------->
              <v-btn class="white--text" color="admin-primary" @click="close">
                Cancel
              </v-btn>
              <v-btn class="white--text" color="admin-primary" @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-------------------------------------------Dialogue Box for delete------------------------------------------->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="white--text"
                color="admin-primary"
                @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                class="white--text"
                color="admin-primary"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!------------------------------------ slot for Team creation Date ------------------------------------->
      <template v-slot:[`item.created_at`]="{ item }">
        <div :class="$vuetify.breakpoint.xs ? 'my-3' : 'my-5'">
          {{ new Date(item.created_at).toLocaleString("en-US", options) }}
        </div>
      </template>
      <!----------------------------------------- Edit Teams Name --------------------------------------------->
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top color="admin-tooltip-color">
          <template v-slot:activator="{ on, attrs }">
            <!-----------------------------------------Icons of Edit and Delete---------------------------------------->
            <v-icon
              disabled
              small
              v-bind="attrs"
              v-on="on"
              class="mr-2 admin-primary--text"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit Team</span>
        </v-tooltip>
        <v-tooltip top color="admin-tooltip-color">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              disabled
              small
              v-bind="attrs"
              v-on="on"
              class="admin-primary--text"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Delete Team</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn class="white--text" color="admin-primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>

    <v-divider></v-divider>

    <!---------------------------------------------Pagination------------------------------------------------>
    <div
      class="d-flex justify-space-between search-container"
      :class="$vuetify.breakpoint.xs ? 'flex-column ' : 'py-4 px-8'"
    >
      <span
        class="d-flex justify-start"
        :class="$vuetify.breakpoint.xs ? 'ml-15 ' : ''"
      >
        <v-subheader class="pb-2"> Rows per Page </v-subheader>
        <v-select
          :items="perPage"
          dense
          color="#432662"
          @change="
            setPerPage($event);
            selected = true;
          "
          class="select-per-page"
          style=""
        >
          <template v-slot:append>
            <v-icon small color="admin-primary" class="py-1"
              >mdi-chevron-down</v-icon
            >
          </template>
          <template v-slot:label>
            <span
              style="font-size: 12px"
              class="pl-3"
              :class="selected ? 'd-none' : ''"
              >{{ itemsPerPage }}</span
            >
          </template>
        </v-select>
      </span>

      <v-pagination
        color="admin-primary"
        id="#pagination"
        v-model="page"
        :length="pageCount"
        size="small"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        total-visible="5"
      ></v-pagination>
    </div>
    <v-snackbar v-model="signoutErrorSnack" right top color="green">
      {{ signoutErrorSnackText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="signoutErrorSnack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<!------------------------------------------------Script-------------------------------------------------->
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    signoutErrorSnackText: "Your session has expired !!!",
    signoutErrorSnack: false,
    firstLoad: true,
    loading: true,
    selected: false,
    select: "",
    search: "",
    dialog: false,
    dialogDelete: false,
    companiesList: [],
    //options for date formate
    options: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    //PAGINATION VARIABLES
    page: 1,
    selectPage: 1,
    pageCount: 0,
    itemsPerPage: 25,
    perPage: [25, 50, 100, 250, 500, 750, 1000],
    //-------------------------------------------------Headers------------------------------------------------>
    headers: [
      {
        text: "Team name",
        align: "start",
        value: "name",
        class: "admin-primary--text tableHeaderBg ",
      },
      {
        text: "Total Members",
        value: "total_members",
        class: "admin-primary--text tableHeaderBg",
      },
      {
        text: "Created Date",
        value: "created_at",
        class: "admin-primary--text tableHeaderBg",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "admin-primary--text tableHeaderBg",
      },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
    },
    defaultItem: {
      name: "",
    },
  }),
  //-------------------------------------------Computed------------------------------------------->
  computed: {
    ...mapGetters(["getTeams", "getTotalRow", "getCompaniesNames"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    teams() {
      return this.getTeams;
    },
  },
  //------------------------------------------Wacthers------------------------------------------->
  watch: {
    //watch property called on selecting company
    async select(val) {
      for (let x of this.getCompaniesNames) {
        if (val == x.name) {
          await this.CompanyTeams(x.id);
          break;
        }
      }
    },

    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  //---------------------------------------------Methods------------------------------------------------>
  methods: {
    ...mapActions(["Teams", "Companies", "CompanyTeams"]),

    async initialize() {
      await this.Companies();
      for (let x of this.getCompaniesNames) {
        this.companiesList.push(x.name);
      }
    },

    editItem(item) {
      this.editedIndex = this.teams.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.teams.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.teams.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.teams[this.editedIndex], this.editedItem);
      } else {
        this.teams.push(this.editedItem);
      }
      this.close();
    },
    setPerPage(perPageEntries) {
      this.itemsPerPage = perPageEntries;
    },
    rowClass() {
      return "admin-table-text";
    },
    testFunction: function () {
      setTimeout(() => {
        if (this.firstLoad) this.firstLoad = false;
        this.loading = false;
      }, 2000);
    },
    },
//---------------------------------------------Mounted------------------------------------------------>
    async  mounted() {
        await this.Teams().then(()=>{},(error)=>{
        
            if(error.response.status==401){
          this.signoutErrorSnack = true
          let vm = this
          setTimeout(()=>{
            vm.$router.push('/')
          },200)
          
        }
      }
    );

    this.testFunction();
    this.initialize();
  },
};
</script>
<!-----------------------------------------------Style------------------------------------------------>
<style scoped>
.tableHeaderBg {
  color: #eeeff7;
}
#pagination .v-pagination .v-pagination__item,
.v-pagination .v-pagination__more,
.v-pagination {
  height: 21px !important;
  min-width: 20px !important;
  font-size: 12px !important;
}

#pagination .v-pagination__navigation .v-icon {
  font-size: 16px !important;
}
#pagination .v-pagination__navigation .v-pagination__item {
  font-size: 16px !important;
  height: 20px;
  width: 20px;
  color: #eeeff7;
}

#pagination .v-pagination__navigation {
  height: 21px !important;

  width: 20px !important;
}

.v-pagination__item .v-pagination__item--active {
  height: 12px !important;
}
.v-pagination__navigation {
  height: 12px !important;
  width: 5px !important;
}
.select-per-page {
  box-sizing: border-box;
  height: 27.98px !important;
  max-width: 53px !important;
  border: 1px solid rgba(67, 38, 98, 0.13);
  border-radius: 2px;
  font-size: 12px !important;
  color: rgba(67, 38, 98, 0.13) !important;
}
.selectcompany {
  width: 50px;
}
</style>
